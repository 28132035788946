import { faLinkedin, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faCircleChevronRight, faStop } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import './../../assets/scss/Footer.scss';

const Footer: React.FC = () => {
    const currentYear = new Date();
    const copyrightText = `2007-${currentYear.getFullYear()} FM Approvals. All rights reserved.`;
    return (
        <footer className="rnfooter">
            <div className="mt-auto pt-2 px-0 mb-3 footer printfooter container-fluid">
                <div className="py-3">
                    <div className="row mx-3 justify-content-center">
                        <div className="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-xs-12 links">
                            <h1 className="footer-heading ml-2 mt-lg-0 mt-sm-3">FM Marks & Auditing</h1>
                            <ul className="ml-1 m-0 p-1">
                                <li><a href="https://www.fmapprovals.com/fm-marks-and-auditing/fm-mark-usage-requirements" target="_blank" rel="noreferrer">Usage Requirements</a></li>
                                <li><a href="https://www.fmapprovals.com/fm-marks-and-auditing/counterfeit-unauthorized-use-of-fm-marks" target="_blank" rel="noreferrer">Unauthorized Use</a></li>
                                <li><a href="https://www.fmapprovals.com/fm-marks-and-auditing/mark-definitions-and-downloads" target="_blank" rel="noreferrer">Definitions and Downloads</a></li>
                                <li><a href="https://www.fmapprovals.com/fm-marks-and-auditing/surveillance-audit-policy" target="_blank" rel="noreferrer">Surveillance Audit Policy</a></li>
                            </ul>
                        </div>

                        <div className="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-xs-12 links">
                            <h1 className="footer-heading ml-2 mt-lg-0 mt-sm-3">Product Alerts, Insights, & Events</h1>
                            <ul className="ml-1 m-0 p-1">
                                <li><a href="https://www.fmapprovals.com/Product-Alerts-and-News-Events/product-alerts" target="_blank" rel="noreferrer">Product Alerts</a></li>
                                <li><a href="https://www.fmapprovals.com/Product-Alerts-and-News-Events/Insights" target="_blank" rel="noreferrer">Insights</a></li>
                                <li><a href="https://www.fmapprovals.com/product-alerts-and-news-events/industry-events" target="_blank" rel="noreferrer">Industry Events</a></li>
                            </ul>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-xs-12 links">
                            <h1 className="footer-heading ml-2 mt-lg-0 mt-sm-3">Links</h1>
                            <ul className="ml-1 m-0 p-1">
                                <li><a href="https://www.fmapprovals.com" target="_blank" rel="noreferrer">FMApprovals.com</a></li>
                                <li><a href="https://www.fmapprovals.com/roofnav" target="_blank" rel="noreferrer">RoofNav</a></li>
                                <li><a href="https://www.fmapprovals.com/approval-standards" target="_blank" rel="noreferrer">Approval Standards</a></li>
                                <li><a href="https://www.fmapprovals.com/customer-portal" target="_blank" rel="noreferrer">Customer Portal</a></li>
                            </ul>
                        </div>

                        <div className="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-xs-12 links">
                            <h1 className="footer-heading ml-2 mt-lg-0 mt-sm-3">The FM Group</h1>
                            <ul className="ml-1 m-0 p-1">
                                <li><a href="https://www.fm.com/" target="_blank" rel="noreferrer">FM</a></li>
                                <li><a href="https://www.fm.com/about-us/our-organization/affiliated" target="_blank" rel="noreferrer">FM Affiliated</a></li>
                                <li><a href="https://www.fm.com/about-us/our-organization/fm-boiler-re" target="_blank" rel="noreferrer">FM Boiler Re</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="footer-middle">
                    <div className="col contact-section row py-3">
                        <div className="col-lg-12 col-xs-12 links">
                            <ul className="m-0 p-1 row justify-content-center">
                                <li className="col-xl-4 col-lg-6 col-md-6 col-sm-6 row section-wrapper">
                                    <a href="mailto:ITsupportroofnav@fmapprovals.com" className="ml-1 mr-3 my-1 row section-wrapper">
                                        <FontAwesomeIcon icon={faCircleChevronRight} aria-hidden="true" className="fa-circle-chevron-right" />&nbsp;
                                        <h1 className="footer-heading mb-0">Contact Us</h1></a>
                                    <span className="col-md-1"></span>
                                    <a href="https://www.fmapprovals.com/office-locator" className="ml-1 mr-3 my-1 row section-wrapper" target="_blank" rel="noreferrer">
                                        <FontAwesomeIcon icon={faCircleChevronRight} aria-hidden="true" className="fa-circle-chevron-right" />&nbsp;
                                        <h1 className="footer-heading mb-0">Find A Local Office</h1></a>

                                </li>
                                <li className="col-xl-4 col-lg-6 col-md-6 col-sm-6 row d-flex align-content-center footer-middle-right">
                                    <div className="footer-middle-text pt-3">Connect With Us</div>
                                    <div className="section-wrapper mt-1">
                                        <span className="col-md-1"></span>
                                        <a href="https://www.linkedin.com/company/fmapprovals" target="_blank" className="mr-2" rel="noreferrer" >
                                            <span className="fa-layers fa-fw">
                                                <FontAwesomeIcon size={'2x'} icon={faLinkedin} aria-hidden="true" data-testid="faLinkedinFooter" />
                                            </span>
                                        </a>
                                        <a href="https://www.youtube.com/channel/UCWq-OGb4eIygp4vMK9AjIaA" target="_blank" className="ml-3" rel="noreferrer">
                                            <span className="fa-layers fa-fw">
                                                <FontAwesomeIcon size={'2x'} icon={faYoutube} aria-hidden="true" data-testid="faYouTubeFooter" />
                                            </span>
                                        </a>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom py-2 px-3">
                    <div className="row col-lg-12 col-xs-12 justify-content-center py-3 footer-bottom-wrapper">
                        <ul className="m-0 p-1 links footer-bottom-links row col-sm-6 col-md-6 col-lg-6 col-xl-4">
                            <li className="mx-4"><a href="https://www.fmapprovals.com/privacy-policy" target="_blank" rel="noreferrer"> Privacy Policy </a></li>
                            <li className="mx-4"><a href="https://www.fmapprovals.com/terms-of-use" target="_blank" rel="noreferrer"> Terms of Use </a> </li>
                        </ul>
                        <div className="copyright-text col-sm-6 col-md-6 col-lg-6 col-xl-4 px-0">© {copyrightText}</div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
