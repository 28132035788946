import { Link, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import theme from '../../theme';
import './RefMaterial.scss';
import fmimg from '../../assets/img/FMApproved_BW.gif';

import { UnitConversions } from './unitconversions';
import { TextOnlyComponent } from '../../components/shared/TextOnlyComponent/TextOnlyComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TextLinkComponent } from '../../components/shared/TextLinkComponent/TextLinkComponent';

export const GuideLines = () => {
  useEffect(() => {
    const anchor = window.location.hash;
    if (anchor) {
      const anchorEl = document.getElementById(anchor);
      if (anchorEl) {
        anchorEl.scrollIntoView();
      }
    }
  }, [window.location.hash]);

  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  const topButton = {
    display: 'flex',
    justifyContent: 'flex-end',
  };
  const handleBackClick = () => {
    history.back();
  };

  return (
    <main className="main-content container-fluid flex-shrink-0 refmaterial-ui">
      <div className={'px-4'}>
        <div className="d-flex justify-content-between align-items-center">
          <Typography className={'headline-1 py-5'} color={theme.palette.rnSteel.steel700}>
            Approval Guidelines
          </Typography>

          <Link onClick={handleBackClick} className="d-flex float-right">
            <TextOnlyComponent buttonText={'Back'} iconName={'arrow_left'} />
          </Link>
        </div>
        <div className={'pb-2'}>
          <Typography color={theme.palette.rnSteel.steel700} className={'headline-3 pb-2'}>
            Approval and Testing Services
          </Typography>
        </div>
        <Typography className={'large-para pb-4'}>
          The materials listed in RoofNav are FM Approved. In every case, these products have been subjected to
          examinations and inspections and have been found to satisfy the criteria for Approval. These examinations and
          inspections are performed by FM Approvals' technicians and engineers according to FM Approvals' requirements
          or recognized national and international requirements. Listed products are readily identifiable and available
          on the market.
        </Typography>
        <Typography className={'large-para pb-4'}>
          Continued Approval depends on satisfactory field performance and conditions covered by the Agreement executed
          between FM Approvals and the client. As a condition of retaining Approval, manufacturing facilities and
          services are subject to periodic unannounced audits. FM Approved products must not be changed or otherwise
          modified without prior authorization by FM Approvals. Unauthorized alterations or modifications may impact the
          safety and performance of the product and will void the Approval.
        </Typography>
        <Typography className={'large-para pb-4'}>
          There is a distinction between "Approval" and "acceptance" by FM Global. Approval is confirmation and
          subsequent listing by FM Approvals that a product has been examined according to requirements and found
          suitable, subject to any limitations stated in the Approval. Products and assemblies listed in RoofNav are FM
          Approved, unless otherwise indicated. Acceptance is confirmation that materials installed at a specific
          location are suitable for their intended use. Accepted materials may be, but are not necessarily, FM Approved.
        </Typography>
        <Typography className={'large-para pb-4'}>
          In addition to testing a product for Approval, FM Approvals tests products to meet certain standards or
          criteria set and required by others. Sometimes a product so tested is marked or advertised to that effect.
          However, Approval by FM Approvals should not be inferred.
        </Typography>
        <Typography className={'large-para pb-4'}>
          Approval of a product does not imply any warranty by FM Approvals against defects or failure in service, nor
          any responsibility in regard to patent infringements.
        </Typography>
        <Typography className={'large-para pb-4'}>
          <div>
            <img src={fmimg} alt="" />
            All FM Approved products, or their packaging, will bear this mark. Only companies providing FM Approved
            products may use this mark on their products or in their literature or advertising. The mark may be used
            only for the specific FM Approved products. The listings in RoofNav should be consulted before products
            affecting property conservation are purchased. Manufacturers should also be consulted for further
            explanation of specific model numbers. The particular product, as listed, should be specified when an order
            is placed with the manufacturer or supplier.
          </div>
        </Typography>
        <div style={topButton}>
          <br></br>
          <span className="mx-4 d-flex float-right px-2 top-navigate" onClick={handleClick}>
            <div className={'button-top'}>
              Back To Top&nbsp;
              <FontAwesomeIcon className="px-1" icon={['fas', 'chevron-up']} />
            </div>
          </span>
        </div>
        <div className={'pb-2'}>
          <Typography id="#Intro" color={theme.palette.rnSteel.steel700} className={'headline-3 pb-2'}>
            Introduction to FM Approved Roof Assemblies
          </Typography>
        </div>
        <Typography className={'large-para pb-4'}>
          Roof assemblies are designed to protect the building, its contents, and its occupancy from expected hazards
          resulting from fire, wind, weathering, and other perils for an extended period of years. Roof covers must be
          designed to protect the underlying components that make up the roof assembly itself, including the covering,
          fasteners, insulation, deck and structural substrate. FM Approved roof constructions have been evaluated and
          proven to reduce or minimize loss potential resulting from these hazards.
        </Typography>
        <Typography className={'large-para pb-4'}>
          All FM Approved roof assemblies have a minimum Class 1-60 wind uplift rating. In addition to the minimum Class
          1-60 rating, FM Approved roofs may also be rated in 15 psf increments; e.g. Class 1-75, Class 1-90, Class
          1-105, etc. up to a maximum wind uplift rating of Class 1-990.
        </Typography>
        <div className={'pb-2'}>
          <Typography id="#GenReqs" color={theme.palette.rnSteel.steel700} className={'headline-3 pb-2'}>
            General Requirements of Approved Roof Constructions
          </Typography>
        </div>
        <Typography className={'large-para pb-4'}>
          To be considered for Approval, roof assemblies must conform to one or more FM Approval Standards which include
          Approval Standards 4450 for <i>Class 1 Insulated Steel Deck Roofs</i> , Approval Standard 4470 for{' '}
          <i>Class 1 Roof Covers</i>, Approval Standard 4471 for <i>Class 1 Panel Roofs</i> or Approval Standard 4435
          for <i>Roof Perimeter Flashing</i>, 4451 for <i>Steel Roof Decking</i>, or 4454 for{' '}
          <i>Lightweight Insulating Concrete Roof Deck</i> .
        </Typography>
        <Typography className={'large-para pb-4'}>
          All roof assemblies listed as FM Approved have been evaluated for performance criteria that include interior
          fire exposure, exterior fire exposure, wind uplift resistance, corrosion resistance for roof fasteners,
          accelerated weathering, hail damage resistance, leakage resistance and foot traffic resistance. All of these
          performance criteria must be met to be considered for Approval as a roof assembly.
        </Typography>
        <Typography className={'large-para pb-4'}>
          In addition to meeting the performance criteria stated in the above Approval Standards, an examination of the
          manufacturing facilities and an audit of quality control procedures is made to evaluate the manufacturer's
          ability to produce the product which is examined and tested, and the marking procedures used to indentify the
          product. These examinations are repeated as part of the FM Approved product follow-up Quality Audit Program.
        </Typography>
        <Typography className={'large-para pb-4'}>
          Continued Approval is based upon production or availability of the product as FM Approved, the continued use
          of acceptable quality control procedures, satisfactory field experience, and compliance with the terms
          stipulated in the Master Agreement.
        </Typography>
        <Typography className={'large-para pb-4'}>
          The roof assemblies within RoofNav are FM Approved only when assembled as listed for each specific cover,
          insulation, fastener, deck or structural substrate. Their compatibility with other roofing components within
          the construction is the responsibility of the listed manufacturer, who should be consulted prior to their use.
          Their performance is extremely dependent upon the substrate to which the system is attached or anchored.
        </Typography>
        <Typography className={'large-para pb-4'}>
          <span className={'paraheading'}>Corner and Perimeter Enhancements:</span> The FM Approved roof assemblies have
          been evaluated for exposure to wind loads and meet a particular rating. The wind uplift loads acting at the
          roof corners (Zone 3) and the roof perimeter (Zone 2) are higher than the loads acting in the field of the
          roof (Zones 1’ and 1). To compensate for the higher loads in Zones 2 and 3, enhancements must be made for the
          securement of all components in the roof assembly if the roof selection is based on the rating of the assembly
          installed in Zone 1. These may be prescriptive or performance based enhancements. If performance based is
          desired, simply select a roof assembly with the needed wind uplift rating for each roof zone.
        </Typography>
        <Typography className={'large-para pb-4'}>
          These enhancements are discussed in detail in FM Global Property Loss Prevention Data Sheets.
        </Typography>
        <Typography className={'large-para pb-4'}>
          <span className={'paraheading'}>Roof Decks:</span> For securement requirements of the roof decking for minimum
          Class 1-60 wind uplift rated roofs, refer to FM Global Property Loss Prevention Data Sheet 1-28.
        </Typography>
        <Typography className={'large-para pb-4'}>
          <span className={'paraheading'}>Above Deck Components:</span> For securement requirements of the above deck
          components for minimum Class 1-60 wind uplift rated roofs, refer to FM Global Property Loss Prevention Data
          Sheet 1-29.
        </Typography>
        <Typography className={'large-para pb-4'}>
          <span className={'paraheading'}>Standing Seam and Panel Roofs:</span> For securement requirements for corner,
          perimeter, and as appropriate roof peak, of standing seam and panel roof systems for minimum Class 1-60 wind
          uplift rated roofs, refer to FM Global Property Loss Prevention Data Sheet 1-31.
        </Typography>
        <Typography className={'large-para pb-4'}>
          <span className={'paraheading'}>Preliminary Insulation Fastening: </span>Refer to Data Sheet 1-29, Table 6 for
          presecurement fastening requirements.
        </Typography>
        <Typography className={'large-para pb-4'}>
          <span className={'paraheading'}>Recover and Reroof: </span>Prior to installing fasteners for recovering and
          reroofing existing decks, fastener withdrawal tests are needed to confirm the adequacy of fasteners and
          fastening pattern. Refer to FM Global Property Loss Prevention Data Sheet 1-29 for details.
        </Typography>
        <Typography className={'large-para pb-4'}>
          <span className={'paraheading'}>Trial Fastener: </span> Installations of row attached mechanically attached
          single ply roof covers over steel deck (new or recover) require that the roof cover be laid perpendicular to
          the ribs of the steel roof deck. This requirement is made to ensure that the mechanical fasteners are
          installed perpendicular to the steel deck ribs and take advantage of the steel deck rib module and engage the
          top flange of the steel deck. Before beginning the securement, a trial fastener must be driven to determine
          that the fastener is in fact engaging the top flange of the steel deck. Engagement in the top flange of the
          steel deck is a requirement of all fasteners for Approval of all steel deck roof constructions.
        </Typography>
        <Typography className={'large-para pb-4'}>
          <span className={'paraheading'}>Torch Applied Roof Coverings:</span>Any roof covering requiring torching for
          securement must be preceded by an FM Approved thermally resistant isolation barrier. The required barrier may
          be supplied as part of the insulation or placed over the roof insulation (unless otherwise noted) and/or
          plastic fasteners to prevent ignition or degradation. Excerise caution. Follow FM Global Property Loss
          Prevention Data Sheet 1-33, Safeguarding Torch-Applied Roof Coverings.
        </Typography>
        <div style={topButton}>
          <br></br>
          <span className="mx-4 d-flex float-right px-2 top-navigate" onClick={handleClick}>
            <div className={'button-top'}>
              Back To Top&nbsp;
              <FontAwesomeIcon className="px-1" icon={['fas', 'chevron-up']} />
            </div>
          </span>
        </div>
        <div className={'pb-2'}>
          <Typography id="#RoofNavSupport" color={theme.palette.rnSteel.steel700} className={'headline-3 pb-2'}>
            RoofNav Support
          </Typography>
        </div>
        <div>
          <Typography className={'large-para pb-4'}>
            If you are new to RoofNav or you are having difficulty using RoofNav, you may find the answers to your
            questions by clicking <TextLinkComponent linkText={'Help'} linkUrl={'/help'} isExternal />, or{' '}
            <TextLinkComponent linkText={'Quick Start Guide'} linkUrl={'/quickstart'} isExternal /> under the question
            mark mark icon. These links will present you with information on how to use the various RoofNav tools, and
            how to the best results from your searches. If you still have questions, or you are experiencing other
            difficulties with RoofNav, please contact the RoofNav Service Desk.
          </Typography>
        </div>
        <div>
          <div className="col-3"></div>
          <div className="col-9">
            <Typography className={'large-para pb-4'}>
              <span className={'supporheading'}>Contacting the RoofNav Service Desk:</span>
              <br></br>
              <br></br>
              <div className={'pl-2'}>
                <div className={'pl-5'}>
                  <ul>
                    <li>+1 (1)888 750 7920 (toll-free in Canada and the United States)</li>
                    <li>+1 (1)401 477 7777</li>
                    <li>
                      E-mail:&nbsp;
                      <a href="mailto:roofnavsupport@fmglobal.com" target="_blank" rel="noreferrer">
                        <span className="a-link">roofnavsupport@fmglobal.com</span>
                      </a>
                      .
                    </li>
                  </ul>
                </div>
                (NOTE: Hours of operation are Monday - Friday, 7:30 am - 5:00 pm Eastern Time)
              </div>
            </Typography>
          </div>
        </div>

        <Typography className={'large-para pb-4'}>
          We appreciate your patience if you are experiencing difficulties with RoofNav. Also, if you have any comments
          or suggestions about RoofNav, we welcome your feedback. <br></br>
          <br></br>Thank you!
        </Typography>
        <div style={topButton}>
          <br></br>
          <span className="mx-4 d-flex float-right px-2 top-navigate" onClick={handleClick}>
            <div className={'button-top'}>
              Back To Top&nbsp;
              <FontAwesomeIcon className="px-1" icon={['fas', 'chevron-up']} />
            </div>
          </span>
        </div>
        <div className={'pb-2'}>
          <Typography id="#UnitConv" color={theme.palette.rnSteel.steel700} className={'headline-3 pb-2'}>
            International System (SI) of Units Conversions
          </Typography>
        </div>
        <Typography className={'large-para pb-4'}>
          Units of measure in RoofNav are listed in U.S. Customary Units first followed by their respective conversions
          to SI most commonly used in the roofing industry. The conversions factors used are provided below. Conversion
          factors are in accordance with ASTM E 380.
        </Typography>
        <UnitConversions></UnitConversions>
        <br></br>
        <div style={topButton}>
          <span className="mx-4 d-flex float-right px-2 top-navigate" onClick={handleClick}>
            <div className={'button-top'}>
              Back To Top&nbsp;
              <FontAwesomeIcon className="px-1" icon={['fas', 'chevron-up']} />
            </div>
          </span>
        </div>
      </div>
    </main>
  );
};
