import { Link, Typography } from '@mui/material';
import React from 'react';
import theme from '../../theme';
import './RefMaterial.scss';
import { getApprovalDocByDocumentId, getDataSheetsByCategoryId } from '../../services/RefferenceMaterialService';
import { TextOnlyComponent } from '../../components/shared/TextOnlyComponent/TextOnlyComponent';
import { DataSheetTable } from './datasheettable';

export const ApprovalStandard = () => {
  const standardList = getDataSheetsByCategoryId(3);
  //console.log(standardList)
  const handleDocument = (docid: number) => {
    loadDocument(docid);
  };
  const loadDocument = async (documentId: number) => {
    const ApprovalDocument = await getApprovalDocByDocumentId(documentId);
    const bytes = atob(ApprovalDocument[0].DocData);
    let length = bytes.length;
    const out = new Uint8Array(length);

    while (length--) {
      out[length] = bytes.charCodeAt(length);
    }
    const blob = new Blob([out], { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(blob);
    window.open(fileURL);
  };
  const handleBackClick = () => {
    history.back();
  };
  const linkStyle = {
    color: theme.palette.rnOrange.darkOrange,
    fontweight: 600,
  };
  return (
    <main className="main-content container-fluid flex-shrink-0 refmaterial-ui">
      <div className={'px-4'}>
        <div className="d-flex justify-content-between align-items-center">
          <Typography className={'headline-1 py-5'} color={theme.palette.rnSteel.steel700}>
            Approvals Standards
          </Typography>

          <Link onClick={handleBackClick} className="d-flex float-right">
            <TextOnlyComponent buttonText={'Back'} iconName={'arrow_left'} />
          </Link>
        </div>
        <Typography className={'large-para pb-4'}>
          The list below shows the Approval Standards pertaining to roofing. Approval Standards can be downloaded below
          as PDF files. To download an Approval Standard, click on the standard number and the file will open in a new
          window.
        </Typography>
        <div className={'pb-2'}>
          <Typography className={'large-para pb-2'}>
            Printed copies of Approval Standards are available from FM Global's Customer Services department. Please
            call (1)877 364 6726 (toll-free from Canada and the United States) or +1 (1)401 477 7744 (outside Canada and
            the United States).
          </Typography>
          <DataSheetTable
            approvalList={standardList}
            handleDocument={handleDocument}
            isStandard={true}
          ></DataSheetTable>
        </div>
      </div>
    </main>
  );
};
