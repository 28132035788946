import { useEffect, useState } from 'react';
import { fetchFreshToken } from '../AuthHelper';
import { IExtendedLayerConfig, ILayer, ISecurementLayer } from '../components/ConfigitProvider';
import { CONFIGIT_MAP } from '../components/constants/CONFIGIT_MAP';
import { getLayerProps } from '../components/constants/LAYER_DATA';
import {
  AllowedValues,
  ConfigurationValue,
  ConfigurationVariable,
  Section,
  SingletonValue,
  VariableAssignment,
} from '../types/configurator';
import * as LayerApi from '../utils/LayerApi';
import { getLayerDataById } from '../utils/LayerTypeCheckUtilities';
import { ConfigitCacheManager } from './CacheService';
import { useLogger } from 'fmg-telemetry-react';

export const configure = async (variableAssignments: VariableAssignment[]) => {
  const variableAssignmentsStr = ConfigitCacheManager.getVariableAssignmentsStr(variableAssignments);
  const response = ConfigitCacheManager.getApiResponse('RoofNavModel', variableAssignmentsStr);
  if (response) {
    if (response.data) return response.data as Section[];
    if (response.loading)
      return (await ConfigitCacheManager.getApiResponsePromise('RoofNavModel', variableAssignmentsStr)) as Section[];
  }
  ConfigitCacheManager.startApiCall('RoofNavModel', variableAssignmentsStr);
  const data: Section[] = (await request(variableAssignments, 'RoofNavModel', 'RoofNavView')).sections;
  const sections = data.map((section) => reOrderValues(section));
  const result = mapWithDatabase(sections);
  ConfigitCacheManager.finishApiCall('RoofNavModel', variableAssignmentsStr, result);
  return result;
};

export const useConfigure = (variableAssignments: VariableAssignment[]) => {
  const logger = useLogger();
  const [data, setData] = useState<Section[]>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>();
  const [backup, setBackup] = useState<VariableAssignment[]>();
  if (backup && JSON.stringify(backup) !== JSON.stringify(variableAssignments)) {
    setData(undefined);
    setLoading(false);
    setError(undefined);
    setBackup(undefined);
  } else if (!loading) {
    configure(variableAssignments)
      .then(
        (sections) => {
          setData(sections);
          setBackup([...variableAssignments]);
        },
        (reason) => {
          setError(reason);
          logger.error('Error in useConfigure', { error: error, msg: error.message });
        }
      )
      .catch((reason) => {
        setError(reason);
      });
    setLoading(true);
  }
  return {
    data,
    loading: !data && !error,
    error,
  };
};

export const configureLayer = async (variableAssignments: VariableAssignment[], model: string): Promise<Section> => {
  const variableAssignmentsStr = ConfigitCacheManager.getVariableAssignmentsStr(variableAssignments);
  const response = ConfigitCacheManager.getApiResponse(model, variableAssignmentsStr);
  if (response) {
    if (response.data) return response.data as Section;
    if (response.loading) {
      return (await ConfigitCacheManager.getApiResponsePromise(model, variableAssignmentsStr)) as Section;
    }
  }
  ConfigitCacheManager.startApiCall(model, variableAssignmentsStr);
  const data = await request(variableAssignments, model);
  const section = sortDecimalValues(data.sections[0]);
  const result = reOrderValues(section);
  ConfigitCacheManager.finishApiCall(model, variableAssignmentsStr, result);
  return result;
};

export const useConfigureLayer = (variableAssignments?: VariableAssignment[], model?: string) => {
  const logger = useLogger();
  const [data, setData] = useState<Section>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>();
  useEffect(() => {
    let isSubscribed = false;
    if (model && variableAssignments) {
      configureLayer(variableAssignments, model)
        .then(
          (section) => {
            if (!isSubscribed) {
              setData(section);
            }

            setLoading(false);
          },
          (reason) => {
            setError(reason);
            setLoading(false);
          }
        )
        .catch((reason) => {
          setError(reason);
          setLoading(false);
          logger.error('Error in useConfigureLayer', { error: error, msg: error.message });
        });
      //console.log(JSON.stringify(variableAssignments));
      setLoading(true);
    }
    return () => {
      isSubscribed = true;
    };
    // }
  }, [model, JSON.stringify(variableAssignments)]);
  return {
    data,
    loading,
    error,
  };
};

const computeVariableAssignment = (
  variableId: string,
  compatibleValues?: string[],
  variables?: ConfigurationVariable[]
) => {
  const allowedValues = variables?.find((obj) => obj.id === variableId)?.values as SingletonValue[];
  return {
    variableId,
    type: 'AllowedValues',
    allowedValues: allowedValues
      ? allowedValues.filter((data) => String(data.value) !== '0' && compatibleValues?.includes(String(data.value)))
      : compatibleValues?.map((data) => {
        return { type: 'SingletonValue', value: data } as AllowedValues;
      }),
  } as VariableAssignment;
};

export const getInitialLayerState = async (
  //sections: Section[],
  variableAssignments: VariableAssignment[],
  allExtendedLayerConfigs: IExtendedLayerConfig[],
  excludedLayerIds: string[],
  layers: ILayer[],
  layerIndex: number,
  model: string,
  prevAllowedExtendedLayerConfigs: ConfigurationValue[] | undefined
) => {
  const exchangeModelVariableAssignments = [...variableAssignments.filter((data) => data.value)];
  let securementRelativeSeqNum = 0,
    curSecurementRelativeSeqNum = 0;
  layers.forEach((layer, index) => {
    if (layer.isSecurementLayer) securementRelativeSeqNum++;
    if (layerIndex === index) {
      if (layer.isSecurementLayer) curSecurementRelativeSeqNum = securementRelativeSeqNum;
      return;
    }
    const allowedValues = layer?.variableAssignments?.find((data) => data.variableId === 'ComponentId_view')
      ?.allowedValues;
    if (allowedValues) {
      const layerData = getLayerDataById(layer.id);
      const layerProps = getLayerProps(layerData);
      let relativeSeqNum = 1,
        numOfDuplicates = 0;
      let variableId = `ComponentId_${layer.model}`;
      if (layerProps.hasDuplicates) {
        if (!layer.isSecurementLayer) {
          for (let i = 0; i < layers.length; i++) {
            if (i === index) break;
            if (layers[i].id === layer.id) relativeSeqNum++;
          }
          for (let i = 0; i < layers.length; i++) {
            if (layers[i].id === layer.id) numOfDuplicates++;
          }
          variableId = `ComponentId_${layer.model}${relativeSeqNum}`;
          const numOfDuplicatesVA = {
            variableId: `NumOfDuplicates_${layer.model}`,
            value: String(numOfDuplicates),
          } as VariableAssignment;
          exchangeModelVariableAssignments.push(numOfDuplicatesVA);
        } else {
          variableId = `ComponentId_GenericSecurement${securementRelativeSeqNum}`;
          exchangeModelVariableAssignments.push({
            variableId: `LayerTypeId_GenericSecurement${relativeSeqNum}`,
            value: layer.id,
          });
        }
      }
      exchangeModelVariableAssignments.push({
        variableId,
        type: 'AllowedValues',
        allowedValues,
      });
    }
  });
  if (securementRelativeSeqNum) {
    const numOfDuplicatesVA = {
      variableId: `NumOfDuplicates_GenericSecurement_view`,
      value: String(securementRelativeSeqNum),
    } as VariableAssignment;
    exchangeModelVariableAssignments.push(numOfDuplicatesVA);
    exchangeModelVariableAssignments.push(
      {
        variableId: `NumOfDuplicates_GenericSecurementOperator`,
        value: 'BiggerOrEqual'
      } as VariableAssignment
    )
  }
  const layer = layers[layerIndex];
  const layerData = getLayerDataById(layer.id);
  const layerProps = getLayerProps(layerData);
  let relativeSeqNum = 1,
    numOfDuplicates = 0;
  if (layerProps.hasDuplicates && !layer.isSecurementLayer) {
    for (let i = 0; i < layers.length; i++) {
      if (i === layerIndex) break;
      if (layers[i].id === layer.id) relativeSeqNum++;
    }
    for (let i = 0; i < layers.length; i++) {
      if (layers[i].id === layer.id) numOfDuplicates++;
    }
    const numOfDuplicatesVA = {
      variableId: `NumOfDuplicates_${model}`,
      value: String(numOfDuplicates),
    } as VariableAssignment;
    exchangeModelVariableAssignments.push(numOfDuplicatesVA);
  }

  if (prevAllowedExtendedLayerConfigs && prevAllowedExtendedLayerConfigs.length > 0) {
    exchangeModelVariableAssignments.push({
      variableId: 'ExtendedLayerConfigSplit_ExtendedLayerConfigId',
      type: 'AllowedValues',
      allowedValues: prevAllowedExtendedLayerConfigs.map((data) => {
        return { type: 'SingletonValue', value: String((data as SingletonValue).value) } as AllowedValues;
      }),
    } as VariableAssignment);
  }

  const sections = await configure(exchangeModelVariableAssignments);
  // if (!model) return [];
  const section = await configureLayer([], model);
  const variables = section.variables;
  let newVariableAssignments: VariableAssignment[] = [];
  // LayerConfigs allowed
  const compatibleExtendedLayerConfigs = getCompatibleExtendedLayerConfigs(sections, allExtendedLayerConfigs);
  const matchingExtendedLayerConfigs = LayerApi.getMatchingLayerConfigs(
    layers,
    excludedLayerIds,
    compatibleExtendedLayerConfigs
  );
  newVariableAssignments.push({
    variableId: 'ExtendedLayerConfigSplit_ExtendedLayerConfigId',
    type: 'AllowedValues',
    allowedValues: matchingExtendedLayerConfigs.map((data) => {
      return { type: 'SingletonValue', value: String(data.ExtendedLayerConfigId) } as AllowedValues;
    }),
  } as VariableAssignment);
  // Products allowed
  let variableId = `ComponentId_${model}${layerProps.hasDuplicates ? relativeSeqNum : ''}`;
  if (layer.isSecurementLayer) variableId = `ComponentId_GenericSecurement${curSecurementRelativeSeqNum}`;
  const compatibleValues = getVariable(sections, variableId)
    ?.values?.filter((data) => !data.incompatible)
    ?.map((data: SingletonValue) => String(data.value));
  newVariableAssignments.push(computeVariableAssignment('ComponentId', compatibleValues, variables));
  const layerVariableAssignments = layer?.variableAssignments?.filter(
    (data) => data.variableId !== 'ExtendedLayerConfigSplit_ExtendedLayerConfigId' && data.variableId !== 'ComponentId'
  );
  const defaultOperatorVariableAssignments = variables
    ?.filter(
      (data) => data.id?.endsWith('Operator') && !layerVariableAssignments?.find((obj) => obj.variableId === data.id)
    )
    .map((data) => {
      return { variableId: data.id, value: 'Equal' } as VariableAssignment;
    });
  if (layerVariableAssignments) newVariableAssignments = [...newVariableAssignments, ...layerVariableAssignments];
  if (defaultOperatorVariableAssignments)
    newVariableAssignments = [...newVariableAssignments, ...defaultOperatorVariableAssignments];
  return newVariableAssignments;
};

export const useGetInitialLayerState = (
  //sections: Section[],
  variableAssignments: VariableAssignment[],
  allExtendedLayerConfigs: IExtendedLayerConfig[],
  excludedLayerIds: string[],
  layers: ILayer[],
  layerIndex: number,
  model: string,
  prevAllowedExtendedLayerConfigs: ConfigurationValue[] | undefined
) => {
  const logger = useLogger();
  const [data, setData] = useState<VariableAssignment[]>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>();
  if (!loading) {
    setLoading(true);
    getInitialLayerState(variableAssignments, allExtendedLayerConfigs, excludedLayerIds, layers, layerIndex, model, prevAllowedExtendedLayerConfigs)
      .then(
        (initialState) => setData(initialState),
        (reason) => setError(reason)
      )
      .catch((reason) => {
        setError(reason);
        logger.error('Error in useGetInitialLayerState', { error: error, msg: error.message });
      });
  }
  return {
    data,
    loading: !data && !error,
    error,
  };
};

export const request = async (variableAssignments: VariableAssignment[], productId: string, viewId?: string) => {
  const token = (await fetchFreshToken()) || '';
  const response = await fetch(`${process.env.REACT_APP_CONFIGIT_URL}`, {
    method: 'post',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      clientId: `${process.env.REACT_APP_CONFIGIT_API_KEY}`,
      Authorization: token ? `Bearer ${token}` : '',
    },
    body: JSON.stringify({
      date: '2017-06-22',
      language: 'EN',
      line: {
        productId,
        variableAssignments: variableAssignments,
      },
      viewId,
    }),
  });
  return await response.json();
};

export const flattenSubSections = (sections: Section[]) => {
  const section = sections[0];
  let variables: ConfigurationVariable[] = [];
  if (section.variables) variables.concat(section.variables);
  section.sections?.forEach((subSection) => {
    if (subSection.variables) variables = variables.concat(subSection.variables);
  });
  section.variables = variables;
  delete section.sections;
  return sections;
};

export const mapWithDatabase = (sections: Section[]) => {
  for (let i = 0; i < sections.length; i++) {
    const section = sections[i];
    if (section.sections) section.sections = mapWithDatabase(section.sections);
    const variables = section.variables;
    if (variables) {
      for (let j = 0; j < variables.length; j++) {
        const variable = variables[j];
        if (variable.id) {
          const map = CONFIGIT_MAP[variable.id];
          if (map) {
            // if (map.label) {
            //   variable.name = map.label;
            // }
            if (map.properties) {
              variable.properties = map.properties;
            }
            if (variable.id?.endsWith('Operator')) {
              if (variable.properties && variable.properties[0].values)
                variable.properties[0].values[0].rangeValues = variables[j + 1].values;
            }
          }
        }
      }
    }
  }
  return sections;
};

export const orderValuesByName = (values: ConfigurationValue[]) => {
  return values.sort((a: any, b: any) =>
    a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase()
      ? 1
      : a.name.toLocaleLowerCase() < b.name.toLocaleLowerCase()
        ? -1
        : 0
  );
};

export const orderAllowedValuesFirst = (values: ConfigurationValue[]) => {
  return values.filter((value) => !value.incompatible).concat(values.filter((value) => value.incompatible));
};

const reOrderValues = (section: Section) => {
  section.variables = section.variables?.map((variable) => {
    const id = variable.id;
    const values = variable.values;
    if (values && values?.length > 3) {
      if (id && id === 'NavAssembly_WindUplift_view') variable.values = orderAllowedValuesFirst(values);
      else variable.values = orderAllowedValuesFirst(orderValuesByName(values));
    }
    return variable;
  });
  return section;
};

const sortDecimalValues = (section: Section) => {
  section.variables = section.variables?.map((variable) => {
    if (variable.properties && variable.properties[0] && variable.properties[0].value === 'decimal') {
      variable.values = variable.values?.sort(
        (a, b) => parseFloat(String((a as SingletonValue).value)) - parseFloat(String((b as SingletonValue).value))
      );
    }
    return variable;
  });
  return section;
};

export const getValidLayerConfigs = (sections: Section[]) => {
  return (
    getVariable(sections, 'LayerConfig_LayerConfigId')
      ?.values?.filter((value) => !value.incompatible)
      ?.map((data: SingletonValue) => data) ?? []
  );
};

export const getAllLayerConfigs = (sections: Section[]) => {
  return getVariable(sections, 'LayerConfig_LayerConfigId')?.values ?? [];
};

export const getCompatibleExtendedLayerConfigs = (
  sections: Section[],
  allExtendedLayerConfigs: IExtendedLayerConfig[]
) => {
  const result = getVariable(sections, 'ExtendedLayerConfigSplit_ExtendedLayerConfigId')
    ?.values?.filter((data: SingletonValue) => !data.incompatible)
    ?.map(
      (data: SingletonValue) =>
        allExtendedLayerConfigs.find((obj) => obj.ExtendedLayerConfigId === Number(data.value)) as IExtendedLayerConfig
    )
    ?.filter((data) => !!data);
  return result ?? [];
};

export const getVariable = (sections: Section[], variableId: string) => {
  for (let i = 0; i < sections.length; i++) {
    const variable = sections[i].variables?.find((data) => data.id === variableId);
    if (variable) return variable;
  }
};

export const updateFilters = (section: Section, newFilters: any) => {
  let filterApplied = false;
  if (!section.variables) return;
  for (let i = 0; i < section.variables.length; i++) {
    const variable = section.variables[i];
    const configitValue = variable.values?.find((value) => value.assigned) as SingletonValue;
    if (variable.id && configitValue) {
      const value = configitValue ? configitValue.value : undefined;
      const metadata = CONFIGIT_MAP[variable.id];
      if (metadata)
        if (metadata.tableName) {
          filterApplied = true;
          newFilters[metadata.tableName + 'Id'] = {
            _eq: parseInt(String(value)),
          };
        } else if (metadata.colName) {
          if (metadata.properties?.[0]?.values?.[0]?.type === 'Boolean') {
            filterApplied = true;
            newFilters[metadata.colName] = {
              _eq: String(value).toLowerCase() === 'true',
            };
          } else {
            const key = value === 'BiggerOrEqual' ? '_gte' : value === 'Equal' ? '_eq' : '_lte';
            const val = section.variables[i + 1].values?.find((value) => value.assigned) as SingletonValue;
            if (val) {
              filterApplied = true;
              newFilters[metadata.colName] = {};
              newFilters[metadata.colName][key] = String(parseFloat(String(val.name)));
            }
          }
        } else if (metadata.addFilters) {
          filterApplied = true;
          metadata.addFilters(newFilters, parseInt(String(value)));
        }
    }
  }
  return filterApplied;
};

export const mapInferredLayerWithConfigurations = (layers: ILayer[], inferredLayers: ILayer[]) => {
  // use key-value collection layerTypeIdNumOccurrence to store layer type id - # of occurrence in inferredLayers
  const layerTypeIdNumOccurrence = new Map<string, number>();
  for (let i = 0; i < inferredLayers.length; i++) {
    if (!layerTypeIdNumOccurrence.has(inferredLayers[i].id)) layerTypeIdNumOccurrence.set(inferredLayers[i].id, 1);
    else
      layerTypeIdNumOccurrence.set(
        inferredLayers[i].id,
        (layerTypeIdNumOccurrence.get(inferredLayers[i].id as string) as number) + 1
      );
  }
  // trackDuplicateLayer is used to track # of occurrence of duplicate layers when loop against inferredLayers array
  const trackDuplicateLayer = new Map<string, number>();
  const resultLayers: ILayer[] = [];
  for (let i = 0; i < inferredLayers.length; i++) {
    let prev;
    if (!inferredLayers[i].autoSelected) {
      if ((layerTypeIdNumOccurrence.get(inferredLayers[i].id as string) as number) === 1) {
        prev = layers.find((data) => data.id === inferredLayers[i].id);
      } else {
        // Duplicate layers with same layer type ids
        if (!trackDuplicateLayer.has(inferredLayers[i].id)) {
          trackDuplicateLayer.set(inferredLayers[i].id, 1);
          prev = layers.find((data) => data.id === inferredLayers[i].id);
        } else {
          // Handle prev for the second or more occurrence of duplicate layers
          const existingNumOccurrence = trackDuplicateLayer.get(inferredLayers[i].id as string) as number;
          const newNumOccurrence = existingNumOccurrence + 1;
          trackDuplicateLayer.set(inferredLayers[i].id, newNumOccurrence + 1);
          let matched = 0;
          for (let j = 0; j < layers.length; j++) {
            if (layers[j].id === inferredLayers[i].id) {
              matched++;
              if (matched === newNumOccurrence) {
                prev = layers[j];
                break;
              }
            }
          }
        }
      }
    }

    if (prev) {
      if (prev.isSecurementLayer) {
        const cur = inferredLayers[i] as ISecurementLayer;
        resultLayers.push({
          ...prev,
          fromLayerNum: cur.fromLayerNum,
          fromLayerTypeId: cur.fromLayerTypeId,
          toLayerNum: cur.toLayerNum,
          toLayerTypeId: cur.toLayerTypeId,
        } as ISecurementLayer);
      } else resultLayers.push(prev);
    } else resultLayers.push(inferredLayers[i]);
  }
  return resultLayers;
};

export const getInitialSelfSecurementState = async (
  variableAssignments: VariableAssignment[],
  allExtendedLayerConfigs: IExtendedLayerConfig[],
  excludedLayerIds: string[],
  layers: ILayer[],
  layerIndex: number,
  modelSelfSecurement: string,
  modelBaseLayer: string
) => {
  const exchangeModelVariableAssignments = [...variableAssignments.filter(data => data.value)];
  let securementRelativeSeqNum = 0, curSecurementRelativeSeqNum = 0;
  layers.forEach((layer, index) => {
    if (layer.isSecurementLayer) securementRelativeSeqNum++;
    if (layerIndex === index) {
      if (layer.isSecurementLayer) curSecurementRelativeSeqNum = securementRelativeSeqNum;
      return;
    }
    const allowedValues = layer?.variableAssignments?.find(
      (data) => data.variableId === 'ComponentId_view'
    )?.allowedValues;
    if (allowedValues) {
      const layerData = getLayerDataById(layer.id);
      const layerProps = getLayerProps(layerData);
      let relativeSeqNum = 1, numOfDuplicates = 0;
      let variableId = `ComponentId_${layer.model}`;
      if (layerProps.hasDuplicates) {
        if (!layer.isSecurementLayer) {
          for (let i = 0; i < layers.length; i++) {
            if (i === index) break;
            if (layers[i].id === layer.id) relativeSeqNum++;
          }
          for (let i = 0; i < layers.length; i++) {
            if (layers[i].id === layer.id) numOfDuplicates++;
          }
          variableId = `ComponentId_${layer.model}${relativeSeqNum}`;
          const numOfDuplicatesVA = {
            variableId: `NumOfDuplicates_${layer.model}`,
            value: String(numOfDuplicates)
          } as VariableAssignment;
          exchangeModelVariableAssignments.push(numOfDuplicatesVA);
        }
        else {
          variableId = `ComponentId_GenericSecurement${securementRelativeSeqNum}`;
          exchangeModelVariableAssignments.push({
            variableId: `LayerTypeId_GenericSecurement${relativeSeqNum}`,
            value: layer.id
          });
        }
      }
      exchangeModelVariableAssignments.push({
        variableId,
        type: 'AllowedValues',
        allowedValues,
      });
    }
  });
  if (securementRelativeSeqNum) {
    const numOfDuplicatesVA = {
      variableId: `NumOfDuplicates_GenericSecurement`,
      value: String(securementRelativeSeqNum)
    } as VariableAssignment;
    exchangeModelVariableAssignments.push(numOfDuplicatesVA);
  }
  const layer = layers[layerIndex];
  const layerData = getLayerDataById(layer.id);
  const layerProps = getLayerProps(layerData);
  let relativeSeqNum = 1, numOfDuplicates = 0;
  if (layerProps.hasDuplicates && !layer.isSecurementLayer) {
    for (let i = 0; i < layers.length; i++) {
      if (i === layerIndex) break;
      if (layers[i].id === layer.id) relativeSeqNum++;
    }
    for (let i = 0; i < layers.length; i++) {
      if (layers[i].id === layer.id) numOfDuplicates++;
    }
    const numOfDuplicatesVA = {
      variableId: `NumOfDuplicates_${modelBaseLayer}`,
      value: String(numOfDuplicates)
    } as VariableAssignment;
    exchangeModelVariableAssignments.push(numOfDuplicatesVA);
  }
  const sections = await configure(exchangeModelVariableAssignments);
  // if (!model) return [];
  const section = await configureLayer([], modelSelfSecurement);
  const variables = section.variables;
  let newVariableAssignments: VariableAssignment[] = [];
  // LayerConfigs allowed
  const compatibleExtendedLayerConfigs = getCompatibleExtendedLayerConfigs(sections, allExtendedLayerConfigs);
  const matchingExtendedLayerConfigs = LayerApi.getMatchingLayerConfigs(
    layers,
    excludedLayerIds,
    compatibleExtendedLayerConfigs
  );
  newVariableAssignments.push({
    variableId: 'ExtendedLayerConfigSplit_ExtendedLayerConfigId',
    type: 'AllowedValues',
    allowedValues: matchingExtendedLayerConfigs.map((data) => {
      return { type: 'SingletonValue', value: String(data.ExtendedLayerConfigId) } as AllowedValues;
    }),
  } as VariableAssignment);
  // Products allowed
  // let variableId = `ComponentId_${modelBaseLayer}${layerProps.hasDuplicates ? relativeSeqNum : ''}`;
  // if (layer.isSecurementLayer) variableId = `ComponentId_GenericSecurement${curSecurementRelativeSeqNum}`;
  // const compatibleValues = getVariable(sections, variableId)?.values
  //   ?.filter(data => !data.incompatible)
  //   ?.map((data: SingletonValue) => String(data.value));
  // newVariableAssignments.push(computeVariableAssignment(
  //   'ComponentId',
  //   compatibleValues,
  //   variables
  // ));
  const allowedComponentIds = variables?.find((obj) => obj.id === 'ComponentId')?.values as SingletonValue[];
  newVariableAssignments.push({
    variableId: 'ComponentId',
    type: 'AllowedValues',
    allowedValues: allowedComponentIds,
  } as VariableAssignment);

  const selfSecurementVariableAssignments = layer?.selfSecurement?.variableAssignments?.filter((data) =>
    data.variableId !== 'ExtendedLayerConfigSplit_ExtendedLayerConfigId'
    && data.variableId !== 'ComponentId'
  );
  const defaultOperatorVariableAssignments = variables
    ?.filter(
      (data) => data.id?.endsWith('Operator') && !selfSecurementVariableAssignments?.find((obj) => obj.variableId === data.id)
    )
    .map((data) => {
      return { variableId: data.id, value: 'Equal' } as VariableAssignment;
    });
  if (selfSecurementVariableAssignments) newVariableAssignments = [...newVariableAssignments, ...selfSecurementVariableAssignments];
  if (defaultOperatorVariableAssignments)
    newVariableAssignments = [...newVariableAssignments, ...defaultOperatorVariableAssignments];
  return newVariableAssignments;
};

export const useGetInitialSelfSecurementState = (
  variableAssignments: VariableAssignment[],
  allExtendedLayerConfigs: IExtendedLayerConfig[],
  excludedLayerIds: string[],
  layers: ILayer[],
  layerIndex: number,
  modelSelfSecurement: string,
  modelBaseLayer: string
) => {
  const logger = useLogger();
  const [data, setData] = useState<VariableAssignment[]>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>();
  if (!loading) {
    setLoading(true);
    getInitialSelfSecurementState(variableAssignments, allExtendedLayerConfigs, excludedLayerIds, layers, layerIndex, modelSelfSecurement, modelBaseLayer)
      .then(
        (initialState) => setData(initialState),
        (reason) => setError(reason)
      )
      .catch((reason) => {
        setError(reason);
        logger.error('Error in useGetInitialSelfSecurementState', { error: error, msg: error.message })
      });
  }
  return {
    data,
    loading: !data && !error,
    error,
  };
};
