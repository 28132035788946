import { IExtendedLayerConfig, ILayer, ISecurementLayer } from '../components/ConfigitProvider';
import { LAYER_DATA, getFlatLayerData, getLayerProps } from '../components/constants/LAYER_DATA';
import { SingletonValue } from '../types/configurator';
import { getLayerDataById } from './LayerTypeCheckUtilities';
import { DetermineSelfSecurementLayerType, DetermineStandardSecurementLayerType } from './SecurementUtilities';

export const numToString = (num?: number) => (num ? String(num).padStart(2, '0') : '00');

const findLayerPos = (layers: ILayer[], layerTypeId: number, layerNum: number) => {
  let count = 0;
  for (let i = 0; i < layers.length; i++) {
    const layer = layers[i];
    if (layer.id === numToString(layerTypeId)) count++;
    if (count === layerNum) return i;
  }
  return -1;
};

const getLayerNum = (layers: ILayer[], index: number, strict = false) => {
  const layer = layers[index];
  const layerData = getLayerDataById(layer.id);
  const layerProps = getLayerProps(layerData);
  let layerNum = '(-[0-9])?';
  if (layerProps.hasDuplicates) {
    let relativeSeqNum = 1, numOfDuplicates = 0;
    for (let i = 0; i < layers.length; i++) {
      if (layer.isSecurementLayer && layers[i].isSecurementLayer) numOfDuplicates++;
      else if (layers[i].id === layer.id) numOfDuplicates++;
    }
    for (let i = 0; i < index; i++) {
      if (layer.isSecurementLayer && layers[i].isSecurementLayer) relativeSeqNum++;
      else if (layers[i].id === layer.id) relativeSeqNum++;
    }
    //layerNum = numOfDuplicates == 1 ? (strict ? `(-${relativeSeqNum})?` : '(-[0-9])?') : `-${relativeSeqNum}`;
    // getLayerNum function is only called by createDuplicateLayerRegex
    layerNum = numOfDuplicates == 1 ? (strict ? `(-${relativeSeqNum})?` : '(-[0-9])?') : '(-[0-9])?';
  };
  return layerNum;
};

const createLayerRegex = (layers: ILayer[], securementPoint?: number, securementType?: 'from' | 'to' | 'layer') => {
  const refs: Record<number, any> = {};
  let name = 'a';
  layers.forEach((layer, index) => {
    if (layer.isSecurementLayer) {
      const securementLayer = layer as ISecurementLayer;
      if (securementLayer.fromLayerTypeId || securementLayer.toLayerTypeId) refs[index] = {};
      if (securementLayer.fromLayerTypeId && (securementPoint !== index || securementType !== 'from')) {
        const pos = findLayerPos(layers, securementLayer.fromLayerTypeId, securementLayer.fromLayerNum ?? 1);
        if (pos > -1) {
          if (refs[pos] && refs[pos].backref) {
            refs[index].fromLayer = { backref: refs[pos].backref };
          } else if (!refs[pos]) {
            refs[pos] = { groupName: name };
            name = String.fromCharCode(name.charCodeAt(0) + 1);
            refs[index].fromLayer = { backref: refs[pos].groupName };
          } else refs[index].fromLayer = { backref: refs[pos].groupName };
        }
      }
      if (securementLayer.toLayerTypeId && (securementPoint !== index || securementType !== 'to')) {
        const pos = findLayerPos(layers, securementLayer.toLayerTypeId, securementLayer.toLayerNum ?? 1);
        if (pos > -1) {
          if (refs[pos] && refs[pos].groupName) {
            refs[index].toLayer = { backref: refs[pos].groupName };
          } else if (refs[pos] && refs[pos].backref) {
            refs[index].toLayer = { backref: refs[pos].backref };
          } else {
            refs[index].toLayer = { groupName: name };
            refs[pos] = { backref: name };
            name = String.fromCharCode(name.charCodeAt(0) + 1);
          }
        }
      }
    }
  });
  const layerRegex = new RegExp(
    `^.*${layers
      .map((layer, index) => {
        // LayerTypeId and may be RepeatNum
        let temp = `L\\(?\\[?${layer.id}(-[0-9])?`;
        if (refs[index] && refs[index].groupName) temp = `L\\(?\\[?${layer.id}(?<${refs[index].groupName}>-[0-9])?`;
        if (refs[index] && refs[index].backref) temp = `L\\(?\\[?${layer.id}\\k<${refs[index].backref}>`;
        // If layer is genric securement, any securement layer is allowed
        if (layer.id === '45') {
          temp = temp.replace(
            '45(-[0-9])?',
            `(${securementPoint === index && securementType === 'layer' ? '?<s>' : ''}(39|41|42|44|45)(-[0-9])?)`
          );
        }
        // if (index !== 0 && !layers[index - 1].isSecurementLayer) temp = ',' + temp;
        if (!layer.isSecurementLayer) {
          // May be self securement
          temp += `(&${layer.hasSelfSecurement ? layer.SelfSecurementId : '[0-9][0-9]'})?`;
          // May be optional and may be permeable
          temp += '\\)?\\]?';
        } else {
          const securementLayer = layer as ISecurementLayer;
          if (securementLayer.fromLayerTypeId || securementLayer.toLayerTypeId) {
            temp += `\\{`;
            if (refs[index] && refs[index].fromLayer) {
              if (refs[index].fromLayer.groupName)
                temp += `${numToString(securementLayer.fromLayerTypeId)}(?<${refs[index].fromLayer.groupName}>-[0-9])?`;
              if (refs[index].fromLayer.backref)
                temp += `${numToString(securementLayer.fromLayerTypeId)}\\k<${refs[index].fromLayer.backref}>`;
            } else if (securementPoint === index && securementType === 'from') temp += '(?<s>[0-9][0-9](-[0-9])?)?';
            else temp += '[0-9][0-9](-[0-9])?';
            temp += ':';
            if (refs[index] && refs[index].toLayer) {
              if (refs[index].toLayer.groupName)
                temp += `${numToString(securementLayer.toLayerTypeId)}(?<${refs[index].toLayer.groupName}>-[0-9])?`;
              if (refs[index].toLayer.backref)
                temp += `${numToString(securementLayer.toLayerTypeId)}\\k<${refs[index].toLayer.backref}>`;
            } else if (securementPoint === index && securementType === 'to') temp += '(?<s>[0-9][0-9](-[0-9])?)?';
            else temp += '[0-9][0-9](-[0-9])?';
            temp += '\\}';
          } else if (securementPoint === index) {
            if (securementType === 'from') temp += '\\{(?<s>[0-9][0-9](-[0-9])?):([0-9][0-9])(-[0-9])?\\}';
            else if (securementType === 'to') temp += '\\{([0-9][0-9])(-[0-9])?:(?<s>[0-9][0-9](-[0-9])?)\\}';
            else temp += '\\{[0-9][0-9](-[0-9])?:([0-9][0-9])?(-[0-9])?\\}';
          } else temp += '\\{[0-9][0-9](-[0-9])?:([0-9][0-9])?(-[0-9])?\\}';
        }
        return temp;
      })
      .join('.*,')}.*$`
  );
  return layerRegex;
};

const createDuplicateLayerRegex = (layers: ILayer[], securementPoint?: number, securementType?: 'from' | 'to' | 'layer', strict = false) => {
  let numOfSecurements = 0;
  for (let i = 0; i < layers.length; i++) {
    if (layers[i].isSecurementLayer) numOfSecurements++;
  }
  const layerRegex = new RegExp(
    `^.*${layers
      .map((layer, index) => {
        const layerNum = getLayerNum(layers, index, strict);
        // LayerTypeId and may be RepeatNum
        let temp = `L\\(?\\[?${layer.id}${layerNum}`;
        if (layer.id === '45') {
          temp = temp.replace(`45${layerNum}`, `(${securementPoint === index && securementType === 'layer' ? '?<s>' : ''}(39|40|41|42|43|44|45)${layerNum})`);
        }
        if (!layer.isSecurementLayer) {
          // May be self securement
          temp += `(&${layer.hasSelfSecurement ? layer.SelfSecurementId : '[0-9][0-9]'})?`;
          // May be optional and may be permeable
          temp += '\\)?\\]?';
        }
        else {
          const securementLayer = layer as ISecurementLayer;
          temp += `\\{`;
          if (securementPoint === index && securementType === 'from')
            temp += '(?<s>[0-9][0-9](-[0-9])?)?';
          else if (securementLayer.fromLayerTypeId) {
            temp += numToString(securementLayer.fromLayerTypeId);
            const pos = findLayerPos(layers, securementLayer.fromLayerTypeId, securementLayer.fromLayerNum ?? 1);
            if (pos > -1) {
              const securementNum = getLayerNum(layers, pos, strict);
              temp += securementNum;
            }
          }
          else temp += '[0-9][0-9](-[0-9])?';
          temp += ':';
          if (securementPoint === index && securementType === 'to')
            temp += '(?<s>[0-9][0-9](-[0-9])?)?';
          else if (securementLayer.toLayerTypeId) {
            temp += numToString(securementLayer.toLayerTypeId);
            const pos = findLayerPos(layers, securementLayer.toLayerTypeId, securementLayer.toLayerNum ?? 1);
            if (pos > -1) {
              const securementNum = getLayerNum(layers, pos, strict);
              temp += securementNum;
            }
          }
          else temp += '[0-9][0-9](-[0-9])?';
          temp += `\\}`;
        }
        return temp;
      })
      .join('.*,')}.*$`
  );
  return layerRegex;
};

const createExcludedLayerRegex = (excludedLayerIds: string[]) => {
  return new RegExp(`^.*(${excludedLayerIds.join('|')}).*$`);
};

const isLayerConfigMatching = (
  extentedLayerConfig: IExtendedLayerConfig,
  layerRegex?: RegExp,
  excludedLayerRegex?: RegExp,
  handleDuplicateLayers = false
) => {
  const configString = handleDuplicateLayers ? extentedLayerConfig.GenericSecurementDuplicateString : extentedLayerConfig.FullConfigString;
  const hasLayers = layerRegex ? layerRegex.test(configString) : true;
  const hasExcludedLayers = excludedLayerRegex ? excludedLayerRegex.test(configString) : false;
  return hasLayers && !hasExcludedLayers;
};

export const getMatchingLayerConfigs = (
  layers: ILayer[],
  excludedLayerIds: string[],
  extentedLayerConfigs: IExtendedLayerConfig[],
  handleDuplicateLayers = false,
  strict = false,
) => {
  // console.log('getMatchingLayerConfigs - starts');
  // console.time('getMatchingLayerConfigs');
  let layerRegex = layers.length ? createLayerRegex(layers) : undefined;
  if (handleDuplicateLayers) layerRegex = layers.length ? createDuplicateLayerRegex(layers, undefined, undefined, strict) : undefined;
  const excludedLayerRegex = excludedLayerIds.length ? createExcludedLayerRegex(excludedLayerIds) : undefined;
  const result = extentedLayerConfigs.filter((data) => isLayerConfigMatching(data, layerRegex, excludedLayerRegex, handleDuplicateLayers));
  // console.timeEnd('getMatchingLayerConfigs');
  // console.log('getMatchingLayerConfigs - ends');
  return result;
};

const isLayerCakeValid = (
  layers: ILayer[],
  excludedLayerIds: string[],
  extentedLayerConfigs: IExtendedLayerConfig[]
) => {
  const layerRegex = layers.length ? createLayerRegex(layers) : undefined;
  const excludedLayerRegex = excludedLayerIds.length ? createExcludedLayerRegex(excludedLayerIds) : undefined;
  return !!extentedLayerConfigs.find((data) => isLayerConfigMatching(data, layerRegex, excludedLayerRegex));
};

export const getLayerInsertPoints = (
  layers: ILayer[],
  excludedLayerIds: string[],
  extentedLayerConfigs: IExtendedLayerConfig[]
) => {
  // console.log('getLayerInsertPoints - starts');
  // console.time('getLayerInsertPoints');
  const insertPoint: ILayer = {
    id: '[0-9][0-9]',
    autoSelected: false,
    // isSecurementLayer: true,
  };
  const insertPoints: number[] = [];
  for (let i = 0; i < layers.length; i++) {
    const temp = [...layers.slice(0, i), insertPoint, ...layers.slice(i)];
    if (isLayerCakeValid(temp, excludedLayerIds, extentedLayerConfigs)) insertPoints.push(i);
  }
  const temp = [...layers, insertPoint];
  if (isLayerCakeValid(temp, excludedLayerIds, extentedLayerConfigs)) insertPoints.push(layers.length);
  // console.timeEnd('getLayerInsertPoints');
  // console.log('getLayerInsertPoints - ends');
  return insertPoints;
};

export const getLayerInsertPointDomain = (
  layers: ILayer[],
  excludedLayerIds: string[],
  extentedLayerConfigs: IExtendedLayerConfig[],
  insertPoint: number
) => {
  // console.log('getLayerInsertPointDomain - starts');
  // console.time('getLayerInsertPointDomain');
  const domain: string[] = [];
  for (let i = 0; i < LAYER_DATA.length; i++) {
    const layerType = LAYER_DATA[i];
    const isSecurementLayer = layerType.id === '11118';
    for (let j = 0; j < layerType.layers.length; j++) {
      const layer: ILayer = {
        id: String(layerType.layers[j].value),
        autoSelected: false,
        isSecurementLayer,
      };
      const temp = [...layers.slice(0, insertPoint), layer, ...layers.slice(insertPoint)];
      if (isLayerCakeValid(temp, excludedLayerIds, extentedLayerConfigs)) domain.push(layer.id);
    }
  }
  // console.timeEnd('getLayerInsertPointDomain');
  // console.log('getLayerInsertPointDomain - ends');
  return domain;
};

const getSmallestExtendedLayerConfig = (extentedLayerConfigs: IExtendedLayerConfig[]) => {
  let result = extentedLayerConfigs[0];
  let min = result.FullConfigString.split(',').length;
  for (let i = 1; i < extentedLayerConfigs.length; i++) {
    const temp = extentedLayerConfigs[i].FullConfigString.split(',').length;
    if (temp < min) {
      result = extentedLayerConfigs[i];
      min = temp;
    }
  }
  return result;
};

const buildLayerCake = (extentedLayerConfig: IExtendedLayerConfig) => {
  return extentedLayerConfig.FullConfigString.split(',').map((data) => {
    const temp = data
      .replace('L', '')
      .replace('(', '')
      .replace(')', '')
      .replace('[', '')
      .replace(']', '')
      .replace('}', '');
    if (temp.includes('{')) {
      const tempFull = temp.split('{');
      const tempLayer = tempFull[0].split('&');
      const id = tempLayer[0].split('-')[0];
      const SelfSecurementId = tempLayer[1];
      const tempSecuremnt = tempFull[1].split(':');
      const tempFrom = tempSecuremnt[0].split('-');
      const fromLayerTypeId = parseInt(tempFrom[0]);
      const fromLayerNum = parseInt(tempFrom[1]) ?? 1;
      const tempTo = tempSecuremnt[1].split('-');
      const toLayerTypeId = parseInt(tempTo[0]);
      const toLayerNum = parseInt(tempTo[1]) ?? 1;
      const securmentLayer: ISecurementLayer = {
        id,
        autoSelected: true,
        isSecurementLayer: true,
        // hasSelfSecurement: !!SelfSecurementId,
        // SelfSecurementId,
        fromLayerNum: isNaN(fromLayerNum) ? 1 : fromLayerNum,
        fromLayerTypeId,
        toLayerTypeId,
        toLayerNum: isNaN(toLayerNum) ? 1 : toLayerNum,
      };
      return securmentLayer as ILayer;
    }
    const tempFull = temp.split('&');
    const id = tempFull[0].split('-')[0];
    const SelfSecurementId = tempFull[1];
    return {
      id,
      autoSelected: true,
      // hasSelfSecurement: !!SelfSecurementId,
      // SelfSecurementId,
    } as ILayer;
  });
};

const isAllLayerConfigsMatching = (layerRegex: RegExp, extentedLayerConfigs: IExtendedLayerConfig[]) => {
  for (let i = 0; i < extentedLayerConfigs.length; i++) {
    if (!layerRegex.test(extentedLayerConfigs[i].FullConfigString)) return false;
  }
  return true;
};

const createExtendedLayerConfig = (layers: ILayer[]) => {
  const FullConfigString = layers
    .map((layer: ISecurementLayer) =>
      layer.isSecurementLayer
        ? `L${layer.id}{${numToString(layer.fromLayerTypeId)}:${numToString(layer.toLayerTypeId)}}`
        : `L${layer.id}`
    )
    .join(',');
  return { ExtendedLayerConfigId: -1, FullConfigString } as IExtendedLayerConfig;
};

// const removeSecurementFromTo = (layer: ILayer, removeType: 'from' | 'to' | 'both') => {
//   const securementLayer = { ...layer } as ISecurementLayer;
//   if (removeType === 'from' || removeType === 'both') {
//     securementLayer.fromLayerTypeId = undefined;
//     securementLayer.fromLayerNum = undefined;
//   }
//   if (removeType === 'to' || removeType === 'both') {
//     securementLayer.toLayerTypeId = undefined;
//     securementLayer.toLayerNum = undefined;
//   }
//   return securementLayer;
// };

// const pushUnique = (queue: ILayer[][], layers: ILayer[]) => {
//   const alreadyAdded = queue.find(
//     (data) => createExtendedLayerConfig(data).FullConfigString === createExtendedLayerConfig(layers).FullConfigString
//   );
//   if (!alreadyAdded) queue.push(layers);
// };

// const pushSampleSecurementLayers = (queue: ILayer[][], curSampleLayers: ILayer[], securementPoint: number) => {
//   let nextSampleLayers = curSampleLayers.map((layer, index) => {
//     if (index === securementPoint) return removeSecurementFromTo(layer, 'from');
//     return layer;
//   });
//   pushUnique(queue, nextSampleLayers);
//   nextSampleLayers = curSampleLayers.map((layer, index) => {
//     if (index === securementPoint) return removeSecurementFromTo(layer, 'to');
//     return layer;
//   });
//   pushUnique(queue, nextSampleLayers);
//   nextSampleLayers = curSampleLayers.map((layer, index) => {
//     if (index === securementPoint) return removeSecurementFromTo(layer, 'both');
//     return layer;
//   });
//   pushUnique(queue, nextSampleLayers);
// };

// const getInferredLayersBFS = (
//   sampleLayers: ILayer[],
//   extentedLayerConfigs: IExtendedLayerConfig[],
//   layerRegex?: RegExp
// ): ILayer[] => {
//   let level = sampleLayers.length;
//   const queue: ILayer[][] = [];
//   queue.push(sampleLayers);
//   let autoSelectedLayers: any = {};
//   while (queue.length) {
//     const curSampleLayers = queue.shift();
//     if (curSampleLayers?.length !== undefined) {
//       if (curSampleLayers.length < level) {
//         const autoSelectedLayerKeys = Object.keys(autoSelectedLayers);
//         if (autoSelectedLayerKeys.length === 1) return autoSelectedLayers[autoSelectedLayerKeys[0]] as ILayer[];
//         // if (autoSelectedLayerKeys.length > 1) {
//         //   return getInferredLayersBFS(
//         //     autoSelectedLayers[autoSelectedLayerKeys[0]],
//         //     autoSelectedLayerKeys.map((data) => autoSelectedLayers[data].join(',')),
//         //     layerRegex
//         //   );
//         // }
//         autoSelectedLayers = {};
//         level = curSampleLayers.length;
//         if (level === 0) return [];
//       }
//       const sampleExtendedLayerConfig = createExtendedLayerConfig(curSampleLayers);
//       let match = true;
//       if (layerRegex && !isAllLayerConfigsMatching(layerRegex, [sampleExtendedLayerConfig])) match = false;
//       else {
//         const sampleLayersRegex = createLayerRegex(curSampleLayers);
//         if (!isAllLayerConfigsMatching(sampleLayersRegex, extentedLayerConfigs)) match = false;
//       }
//       if (match) return curSampleLayers;
//       // if (match) autoSelectedLayers[sampleExtendedLayerConfig.FullConfigString] = curSampleLayers;
//       for (let i = 0; i < curSampleLayers.length; i++) {
//         const layerToBeRemoved = curSampleLayers[i];
//         if (layerToBeRemoved.isSecurementLayer) pushSampleSecurementLayers(queue, curSampleLayers, i);
//         const nextSampleLayers = curSampleLayers.filter((layer, index) => index !== i);
//         pushUnique(queue, nextSampleLayers);
//       }
//     }
//   }
//   return [];
// };

// const getInferredLayersBottomUpUtil = (
//   sampleLayers: ILayer[],
//   extentedLayerConfigs: IExtendedLayerConfig[],
//   solutions: ILayer[][]
// ) => {
//   const newSolutions: ILayer[][] = [];
//   solutions.forEach((solution) => {
//     let i = 0;
//     solution.forEach((layer) => {
//       while (i < sampleLayers.length && sampleLayers[i].id !== layer.id) i++;
//     });
//     for (i = i + 1; i < sampleLayers.length; i++) {
//       const newSolution = [...solution, sampleLayers[i]];
//       const newSolutionRegex = createLayerRegex(newSolution);
//       if (!isAllLayerConfigsMatching(newSolutionRegex, extentedLayerConfigs)) newSolutions.push(newSolution);
//     }
//   });
//   return newSolutions;
// };

// const getInferredLayersBottomUp = (
//   sampleLayers: ILayer[],
//   extentedLayerConfigs: IExtendedLayerConfig[],
//   layers: ILayer[]
// ) => {
//   const solutions: ILayer[][] = [];
//   if (layers.length) solutions.push(layers);
//   else {
//     for (let i = 0; i < sampleLayers.length; i++) {
//       solutions.push([sampleLayers[i]]);
//     }
//   }
//   while (solutions.length > 1) {
//     let newSolutions = getInferredLayersBottomUpUtil(sampleLayers, extentedLayerConfigs, solutions);
//     if (!newSolutions.length) newSolutions = [solutions[0]];
//   }
//   return solutions[0];
// };

const getInferredLayersGreedyFindNextSolution = (
  sampleLayer: ILayer,
  extentedLayerConfigs: IExtendedLayerConfig[],
  solution: ILayer[],
  layerRegex?: RegExp
) => {
  for (let i = 0; i <= solution.length; i++) {
    const newSolution = [...solution.slice(0, i), sampleLayer, ...solution.slice(i)];
    let relSeqNum = 1;
    for (let j = 0; j < i; j++) {
      if (solution[j].id === sampleLayer.id) relSeqNum++;
    }
    for (let j = i + 1; j < newSolution.length; j++) {
      if (newSolution[j].isSecurementLayer) {
        const securmentLayer = { ...newSolution[j] } as ISecurementLayer;
        if (securmentLayer.fromLayerTypeId === Number(sampleLayer.id) && securmentLayer.fromLayerNum && securmentLayer.fromLayerNum >= relSeqNum) {
          securmentLayer.fromLayerNum++;
          newSolution[j] = securmentLayer;
        }
        if (securmentLayer.toLayerTypeId === Number(sampleLayer.id) && securmentLayer.toLayerNum && securmentLayer.toLayerNum >= relSeqNum) {
          securmentLayer.toLayerNum++;
          newSolution[j] = securmentLayer;
        }
      }
    }
    const newSolutionExtendedLayerConfig = createExtendedLayerConfig(newSolution);
    if (!layerRegex || isAllLayerConfigsMatching(layerRegex, [newSolutionExtendedLayerConfig])) {
      const newSolutionRegex = createLayerRegex(newSolution);
      if (isAllLayerConfigsMatching(newSolutionRegex, extentedLayerConfigs)) return newSolution;
    }
  }
};

const getInferredLayersGreedyUtil = (
  sampleLayers: ILayer[],
  extentedLayerConfigs: IExtendedLayerConfig[],
  solution: ILayer[],
  layerRegex?: RegExp
) => {
  for (let i = 0; i < sampleLayers.length; i++) {
    let newSolution = getInferredLayersGreedyFindNextSolution(
      sampleLayers[i],
      extentedLayerConfigs,
      solution,
      layerRegex
    );
    if (newSolution) return newSolution;
    if (sampleLayers[i].isSecurementLayer) {
      const securementLayer = { ...sampleLayers[i] } as ISecurementLayer;
      newSolution = getInferredLayersGreedyFindNextSolution(
        { ...securementLayer, fromLayerNum: undefined, fromLayerTypeId: undefined } as ISecurementLayer,
        extentedLayerConfigs,
        solution,
        layerRegex
      );
      if (newSolution) return newSolution;
      newSolution = getInferredLayersGreedyFindNextSolution(
        { ...securementLayer, toLayerNum: undefined, toLayerTypeId: undefined } as ISecurementLayer,
        extentedLayerConfigs,
        solution,
        layerRegex
      );
      if (newSolution) return newSolution;
      newSolution = getInferredLayersGreedyFindNextSolution(
        {
          ...securementLayer,
          fromLayerNum: undefined,
          fromLayerTypeId: undefined,
          toLayerNum: undefined,
          toLayerTypeId: undefined,
        } as ISecurementLayer,
        extentedLayerConfigs,
        solution,
        layerRegex
      );
      if (newSolution) return newSolution;
    }
  }
};

const getInferredLayersGreedy = (
  sampleLayers: ILayer[],
  extentedLayerConfigs: IExtendedLayerConfig[],
  layers: ILayer[]
) => {
  let solution: ILayer[] = layers;
  let layerRegex: RegExp | undefined = undefined;
  if (layers.find((layer) => layer.isSecurementLayer)) {
    layerRegex = createLayerRegex(layers);
  }
  while (solution) {
    const newSolution = getInferredLayersGreedyUtil(sampleLayers, extentedLayerConfigs, solution, layerRegex);
    if (newSolution) solution = newSolution;
    else {
      for (let i = 0; i < solution.length; i++) {
        if (solution[i].isSecurementLayer) {
          const securementLayer: ISecurementLayer = solution[i];
          if (securementLayer.fromLayerTypeId) {
            let layerNum = securementLayer.fromLayerNum ?? 1;
            for (let k = 0; k < i && layerNum > 0; k++) {
              if (solution[k].id === numToString(securementLayer.fromLayerTypeId)) layerNum--;
            }
            if (layerNum !== 0) {
              securementLayer.fromLayerNum = undefined;
              securementLayer.fromLayerTypeId = undefined;
            }
          }
          if (securementLayer.toLayerTypeId) {
            let layerNum = securementLayer.toLayerNum ?? 1;
            for (let k = i + 1; k < solution.length && layerNum > 0; k++) {
              if (solution[k].id === numToString(securementLayer.toLayerTypeId)) layerNum--;
            }
            if (layerNum !== 0) {
              securementLayer.toLayerNum = undefined;
              securementLayer.toLayerTypeId = undefined;
            }
          }
        }
      }
      return solution;
    }
  }
  return solution;
};

const isSecurementUpdateValid = (
  sampleLayers: ILayer[],
  securementLayer: ISecurementLayer,
  i: number,
  layerRegex?: RegExp
) => {
  if (!layerRegex) return true;
  const nextSampleLayers = sampleLayers.map((layer, index) => {
    if (index === i) return securementLayer;
    return layer;
  });
  const sampleExtendedLayerConfig = createExtendedLayerConfig(nextSampleLayers);
  return isAllLayerConfigsMatching(layerRegex, [sampleExtendedLayerConfig]);
};

const removeSampleSecurements = (sampleLayers: ILayer[], layerRegex?: RegExp) => {
  for (let i = 0; i < sampleLayers.length; i++) {
    const layer = sampleLayers[i];
    if (layer.isSecurementLayer) {
      let securementLayer = layer as ISecurementLayer;
      if (securementLayer.fromLayerTypeId) {
        securementLayer = {
          ...layer,
          fromLayerNum: undefined,
          fromLayerTypeId: undefined,
        };
        if (isSecurementUpdateValid(sampleLayers, securementLayer, i, layerRegex)) {
          securementLayer = layer as ISecurementLayer;
          securementLayer.fromLayerNum = undefined;
          securementLayer.fromLayerTypeId = undefined;
        }
      }
      if (securementLayer.toLayerTypeId) {
        securementLayer = {
          ...layer,
          toLayerNum: undefined,
          toLayerTypeId: undefined,
        };
        if (isSecurementUpdateValid(sampleLayers, securementLayer, i, layerRegex)) {
          securementLayer = layer as ISecurementLayer;
          securementLayer.toLayerNum = undefined;
          securementLayer.toLayerTypeId = undefined;
        }
      }
    }
  }
};

const removeUnnecessaryLayers = (sampleLayers: ILayer[], extentedLayerConfigs: IExtendedLayerConfig[]) => {
  return sampleLayers.filter((layer) => {
    return !extentedLayerConfigs.find(
      (extentedLayerConfig) =>
        !(
          extentedLayerConfig.FullConfigString.includes(`L${layer.id}`) ||
          extentedLayerConfig.FullConfigString.includes(`L[${layer.id}`) ||
          extentedLayerConfig.FullConfigString.includes(`L(${layer.id}`)
        )
    );
  });
};

const compareExtentedLayerConfigs = (a: IExtendedLayerConfig, b: IExtendedLayerConfig) => {
  return a.FullConfigString.split(',').length - b.FullConfigString.split(',').length;
};

export const getInferredLayers = (layers: ILayer[], extentedLayerConfigs: IExtendedLayerConfig[]) => {
  // console.log('getInferredLayers - starts');
  // console.time('getInferredLayers');
  if (!extentedLayerConfigs.length) return layers;
  const layerRegex = layers.length ? createLayerRegex(layers) : undefined;
  extentedLayerConfigs = extentedLayerConfigs.sort(compareExtentedLayerConfigs);
  let sampleLayers = buildLayerCake(getSmallestExtendedLayerConfig(extentedLayerConfigs));
  sampleLayers = removeUnnecessaryLayers(sampleLayers, extentedLayerConfigs);
  // removeSampleSecurements(sampleLayers, layerRegex);
  // console.time('getInferredLayersBFS');
  // const inferredLayers = getInferredLayersBFS(sampleLayers, extentedLayerConfigs, layerRegex);
  // console.log(inferredLayers);
  // console.timeEnd('getInferredLayersBFS');
  const inferredLayers = getInferredLayersGreedy(sampleLayers, extentedLayerConfigs, layers);
  // console.log(inferredLayers);
  if (!inferredLayers.length) return layers;
  // const result = updateSecurementTypes(inferredLayers);
  // console.timeEnd('getInferredLayers');
  // console.log('getInferredLayers - ends');
  // const processedLayers = processInferredSecurement(inferredLayers);
  const processedLayers = inferredLayers.filter((layer) => !(layer.autoSelected && layer.isSecurementLayer));
  return processedLayers;
};

const processInferredSecurement = (inferredLayers: ILayer[]) => {
  // Check if inferred securement exists. If not, not need for further check and process
  if (inferredLayers.find((layer) => layer.autoSelected && layer.isSecurementLayer) === undefined) {
    return inferredLayers;
  }

  // Inferred securement exists. Need further check / process
  // Correct inferred specific securement which has no from info
  inferredLayers = correctInferredProblematicSpecificSecurement(inferredLayers);
  // If any securement layer is configured, the auto inferred dummy securement layers may be removed
  inferredLayers = removeInferredDummySecurementLayer(inferredLayers);

  return inferredLayers;
};

const correctInferredProblematicSpecificSecurement = (inferredLayers: ILayer[]) => {
  if (inferredLayers.find((layer) => layer.autoSelected && layer.isSecurementLayer && layer.id !== '45')) {
    // Search cake has auto added specific securement layer
    for (let i = 0; i < inferredLayers.length; i++) {
      if (inferredLayers[i].autoSelected && inferredLayers[i].isSecurementLayer && inferredLayers[i].id !== '45') {
        const securementLayer = inferredLayers[i] as ISecurementLayer;
        if (securementLayer.fromLayerNum === undefined || securementLayer.fromLayerTypeId === undefined) {
          securementLayer.id = '45';
        }
      }
    }
  }

  return inferredLayers;
};

const removeInferredDummySecurementLayer = (inferredLayers: ILayer[]) => {
  // If search cake has no explicitly added securement layer, no further processing on inferredLayers
  if (inferredLayers.find((layer) => layer.autoSelected === false && layer.isSecurementLayer) === undefined) {
    return inferredLayers;
  }

  // If any securement layer is configured, the auto inferred dummy securement layers (general securement type, no from/to info) may be removed
  if (
    inferredLayers.find(
      (layer) => layer.autoSelected === false && layer.isSecurementLayer && layer.variableAssignments?.length
    )
  ) {
    return inferredLayers.filter((layer) => {
      if (layer.autoSelected && layer.id === '45' && layer.isSecurementLayer) {
        const securementLayer = layer as ISecurementLayer;
        return securementLayer.fromLayerNum || securementLayer.toLayerNum ? true : false;
      } else return true;
    });
  } else return inferredLayers;
};

const updateSecurementTypes = (layers: ILayer[]) => {
  return layers.map((layer) => {
    if (layer.isSecurementLayer) {
      const securementLayer = { ...layer } as ISecurementLayer;
      securementLayer.id = DetermineStandardSecurementLayerType(
        securementLayer.fromLayerTypeId ?? 0,
        securementLayer.toLayerTypeId ?? 0
      ).toString();
      return securementLayer;
    }
    return layer;
  });
};

export const getSecurementDomain = (
  layers: ILayer[],
  extentedLayerConfigs: IExtendedLayerConfig[],
  securementPoint: number,
  securementType: 'from' | 'to'
) => {
  // console.log('getSecurementDomain - starts');
  // console.time('getSecurementDomain');
  const layerRegex = createDuplicateLayerRegex(layers, securementPoint, securementType, false);
  const result: string[] = [];
  for (let i = 0; i < extentedLayerConfigs.length; i++) {
    let { GenericSecurementDuplicateString } = extentedLayerConfigs[i];
    let match = layerRegex.exec(GenericSecurementDuplicateString)?.groups?.s;
    while (match) {
      const removeMatchPattern =
        securementType === 'from' ? `\{${match}:[0-9][0-9](-[0-9])?\}` : `\{[0-9][0-9](-[0-9])?:${match}\}`;
      const temp = match.split('-');
      const layerTypeId = parseInt(temp[0]);
      if (!temp[1]) {
        match += '-1';
      }
      const layerNum = temp[1] ? parseInt(temp[1]) : 1;
      const pos = findLayerPos(layers, layerTypeId, layerNum);

      let isValidFromTo = false;
      if (pos > -1) { // This matching from/to layer type with layer number is valid based on current layer cake
        // Further check if this matching from/to layer is valid based on its relative position against securement from/to point
        if (securementType === 'from' && pos < securementPoint) {
          isValidFromTo = true;
        }
        if (securementType === 'to' && pos > securementPoint) {
          isValidFromTo = true;
        }
      }

      if (isValidFromTo && !result.includes(match)) {
        const newLayerRegex = createDuplicateLayerRegex(
          layers.map((data, index) => {
            if (index === securementPoint) {
              const temp = { ...data } as ISecurementLayer;
              if (securementType === 'from') {
                temp.fromLayerNum = layerNum;
                temp.fromLayerTypeId = layerTypeId;
              }
              if (securementType === 'to') {
                temp.toLayerNum = layerNum;
                temp.toLayerTypeId = layerTypeId;
              }
              return temp;
            }
            return data;
          }),
          undefined,
          undefined,
          false
        );
        if (newLayerRegex.test(GenericSecurementDuplicateString)) result.push(match);
      }
      GenericSecurementDuplicateString = GenericSecurementDuplicateString.replace(new RegExp(removeMatchPattern, 'g'), '');
      match = layerRegex.exec(GenericSecurementDuplicateString)?.groups?.s;
    }
  }
  // console.timeEnd('getSecurementDomain');
  // console.log('getSecurementDomain - ends');
  return result;
};

export const getSpecificSecurementType = (
  layers: ILayer[],
  extentedLayerConfigs: IExtendedLayerConfig[],
  securementPoint: number
) => {
  // const securementLayer = layers[securementPoint] as ISecurementLayer;
  // const id = DetermineStandardSecurementLayerType(
  //   securementLayer.fromLayerTypeId ?? 0,
  //   securementLayer.toLayerTypeId ?? 0
  // ).toString();
  // if (id !== securementLayer.id) return id;
  const layerRegex = createLayerRegex(
    layers.map((data, index) => {
      if (index !== securementPoint) return data;
      return { ...data, id: '45' } as ILayer;
    }),
    securementPoint,
    'layer'
  );
  const result: number[] = [];
  for (let i = 0; i < extentedLayerConfigs.length; i++) {
    let { FullConfigString } = extentedLayerConfigs[i];
    let match = layerRegex.exec(FullConfigString)?.groups?.s;
    while (match) {
      const removeMatchPattern = `L\\(?\\[?${match}\\]?\\)?\{[0-9][0-9](-[0-9])?:[0-9][0-9](-[0-9])?\}`;
      const temp = match.split('-');
      const layerTypeId = parseInt(temp[0]);
      if (!result.includes(layerTypeId)) result.push(layerTypeId);
      FullConfigString = FullConfigString.replace(new RegExp(removeMatchPattern, 'g'), '');
      match = layerRegex.exec(FullConfigString)?.groups?.s;
    }
  }
  if (!result.length) return layers[securementPoint].id;
  if (result.length === 1) return result[0].toString();
  return '45';
};

export const getExcludedLayerDomain = (
  extentedLayerConfigs: IExtendedLayerConfig[],
  excludedLayers: string[]
): SingletonValue[] => {
  // console.log('getExcludedLayerDomain - starts');
  // console.time('getExcludedLayerDomain');
  const layerData = getFlatLayerData().filter(
    (layer) => layer.value !== '51' && layer.value !== '40' && layer.value !== '43'
  );
  const result = layerData.map((layer) => {
    let canExclude = false;
    let canInclude = false;
    for (let i = 0; i < extentedLayerConfigs.length; i++) {
      const { FullConfigString } = extentedLayerConfigs[i];
      if (
        !(
          FullConfigString.includes(`L${layer.value}`) ||
          FullConfigString.includes(`L[${layer.value}`) ||
          FullConfigString.includes(`L(${layer.value}`)
        )
      )
        canExclude = true;
      else canInclude = true;
      if (canExclude && canInclude) break;
    }
    layer.incompatible = !(canExclude && canInclude);
    layer.assigned = excludedLayers.indexOf(String(layer.value)) > -1 ? 'byUser' : undefined;
    return layer;
  });
  // console.timeEnd('getExcludedLayerDomain');
  // console.log('getExcludedLayerDomain - ends');
  return result;
};

export const checkIfLayerCanContainSelfSecurement = (
  layers: ILayer[],
  excludedLayerIds: string[],
  extentedLayerConfigs: IExtendedLayerConfig[],
  targetLayerIndex: number
) => {
  if (layers[targetLayerIndex].isSecurementLayer) {
    // Only component layer may have self-securement. Regular securement layer can not have self-securement
    return false;
  }

  if (layers[targetLayerIndex].hasSelfSecurement && layers[targetLayerIndex].SelfSecurementId) {
    // the target layer has set self-securement already
    return true;
  }

  // the target layer is a component layer and self-securement has not been set
  const temp = [...layers];
  temp[targetLayerIndex].hasSelfSecurement = true;
  temp[targetLayerIndex].SelfSecurementId = DetermineSelfSecurementLayerType(
    parseInt(temp[targetLayerIndex].id)
  ).toString();

  return isLayerCakeValid(temp, excludedLayerIds, extentedLayerConfigs) ? true : false;
};
